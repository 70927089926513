<script setup lang="ts">
    import piniaStore from '@/store';

    const userStore = piniaStore.useUserStore;
    const { userState } = storeToRefs(userStore);

    const route = useRoute();

    interface iMenu {
        title: string
        icon: string
        href: string
    }

    const patientFeatureMenu = computed(() => {
        const userData = userState.value.data;

        let menu: iMenu[] = [];

        if (userState.value.isLogin && userData.role.pagePermission.length > 0) {
            menu = userData.role.pagePermission
                .filter(e => e.id !== 1)
                .map(e => ({
                    title: e.name,
                    icon: e.icon,
                    href: `/patient/${route.params?.patient_id}/${e.href}`
                }));
        } else {
            menu = [];
        }

        return menu;
    });

    const inPatient = computed(() => {
        return (route.matched[0].name === 'Patient');
    });

    onMounted(() => {
        const stickyElm = document.querySelector('.navBlock');
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1),
            { threshold: [1] }
        );

        if (stickyElm) {
            observer.observe(stickyElm);
        }
    });
</script>

<template>
    <nav class="navBlock">
        <h1 class="currentPageTitle" style="margin-left: 61px;">
            <ElSvgIcon :name="route.meta.icon" />
            {{ route.meta.title }}
            <div class="cover" />
        </h1>

        <transition name="fadeY">
            <div v-if="inPatient" class="menu">
                <template v-for="(item, i) in patientFeatureMenu" :key="`nav_${i}`">
                    <div
                        v-if="item.title === route.matched[1].meta.title"
                        class="Btn current"
                    >
                        {{ item.title }}
                    </div>

                    <ElBtn v-else :href="item.href">
                        {{ item.title }}
                    </ElBtn>
                </template>
            </div>
        </transition>
    </nav>
</template>

<style lang="scss">
    .navBlock {
        position: sticky;
        top: -1px;
        flex-shrink: 0;
        transition: .25s cubic-bezier(0.645, 0.045, 0.355, 1);
        z-index: 100;
        @include setFlex(flex-start, center, 6px);
        @include setSize(100%, 65px);

        .icon.back {
            background: #fff;
            @include setSize(55px, 55px);
            padding: .67rem;
            border-radius: 50%;
            fill: $colorFont;
            cursor: pointer;
            transform: translateY(-2px);
        }

        .currentPageTitle {
            position: relative;
            background: #fff;
            height: 100%;
            padding: .56rem 2.5rem .56rem 1.39rem;
            border-radius: 50px 100px 0 0;
            color: var(--color-primary);
            font-size: 1.11rem;
            font-weight: normal;
            @include setFlex(flex-start, center, 8px);
            transition: .2s $cubic-FiSo;
            &::before,
            &::after {
                content: '';
                position: absolute;
                background: transparent;
                @include setSize(30px, 30px);
                z-index: -1;
            }
            &::before{
                bottom: 0;
                left: -30px;
                border-radius: 0 0 35px;
                box-shadow: 5px 5px 0 5px #fff;
            }
            &::after {
                right: -42px;
                bottom: -10px;
                @include setSize(40px, 70px);
                border-radius: 0 0 0 55px;
                box-shadow: -10px 10px 0 10px #fff;
                transform: rotateZ(-36deg);
            }

            .cover {
                position: absolute;
                right: -65px;
                bottom: 0;
                background: $colorBack;
                @include setSize(25px, 10px);
            }

            .icon {
                @include setSize(30px, 30px);
                fill: var(--color-primary);
            }
        }

        .menu {
            align-self: flex-start;
            flex: 1;
            gap: 15px;
            transform: translateY(-1px);
            @include setFlex(flex-end, center);
            @include useBreakPoint('md') {
                .Btn {
                    padding: .56rem 1rem;
                }
            };
        }

        &.stuck {
            background: #fff;
            border-radius: 0;
            box-shadow: 0 1px 2px 0 rgba($color: #000, $alpha: 25%);
            .currentPageTitle {
                margin-left: 0 !important;
                &::before,
                &::after,
                .cover { visibility: hidden; }
            }

            .menu { align-self: center; }
        }
    }
</style>
